import React, { FC, memo, Children, useCallback } from 'react';
import cn from 'classnames';

import { Icon } from '@brandandcelebrities/kolkit';

import { AttachmentState } from 'reducers/messaging';

import styles from './Attachments.module.scss';

interface Props {
  attachments: AttachmentState[];
  inline?: boolean;
  readOnly?: boolean;
  alignRight?: boolean;
  onRemove?: (a: AttachmentState) => void;
}

const Attachments: FC<Props> = ({ attachments, readOnly, onRemove, alignRight }) => {

  const handleRemove = useCallback(
    (a) => onRemove && onRemove(a),
    [onRemove]
  );

  const cnWrapper = cn(styles.wrapper, {
    [styles.editable]: !readOnly,
    [styles.alignRight]: alignRight,
  });

  return (
    <div className={cnWrapper}>
      {Children.toArray((attachments).map((a) => {
        // eslint-disable-next-line no-underscore-dangle
        if (a._destroy) return null;
        return readOnly
          ? (
              <a
                href={a?.url}
                target="_blank"
                className={styles.item}
                rel="noopener noreferrer"
              >
                <Icon
                  label="paperclip"
                  width={16}
                  className={styles.icon}
                />
                {a?.name}
              </a>
            )
          : (
              <div className={styles.item}>
                <span className={styles.name}>{a?.name}</span>
                <Icon
                  label="times"
                  fill="#fff"
                  isButton
                  onClick={() => handleRemove(a)}
                />
              </div>
            )
      }))}
    </div>
  );
};

Attachments.displayName = 'Attachments';

Attachments.defaultProps = {
  attachments: [],
  alignRight: false,
}

export default memo(Attachments);

/* eslint-disable no-alert */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { EditorState } from 'draft-js';
import isEmpty from 'lodash.isempty';
import cn from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { Button, Text, Truncate } from '@brandandcelebrities/kolkit';

import { transformDroppedFiles } from 'utils/dropzone';
import { useLexique } from 'utils/redux';
import { markdownToEditor, pasteEditorStyles } from 'utils/richTextEditor';
import usePrevious from 'utils/hooks/usePrevious';

import { acceptedFormat, acceptedFiles } from 'config/files';

import { Attachments, TextEditor } from 'containers/messagingPage/Components';
import { ChatLexique } from 'locales/types/containers/messagingPage';

import 'emoji-mart/css/emoji-mart.css';
import styles from './Chat.module.scss';

const DEFAULT_STATE = {
  content: EditorState.createEmpty(),
  attachments: [],
  draftId: null,
  draftSaved: true,
}

const ChatFooter = ({ conversationId, draftMessage, onSend, onSaveDraft, locale, withoutToolbar }) => {
  const lexiqueChat = useLexique<ChatLexique>('containers.messagingPage.chat');
  const lexiqueAttachentError = useLexique('containers.messagingPage.attachmentError');
  const prevConversationId = usePrevious(conversationId);
  const [message, setMessage] = useState(DEFAULT_STATE);
  const [show, setShow] = useState(false);

  useEffect(
    () => {
      if (draftMessage) {
        setMessage({
          content: markdownToEditor(draftMessage.content),
          attachments: draftMessage.attachments,
          draftId: draftMessage.id,
          draftSaved: true,
        });
      } else {
        setMessage(DEFAULT_STATE);
      }
    },
    [draftMessage]
  );

  const showForm = useCallback(
    () => setShow(true),
    []
  );

  const hideForm = useCallback(
    () => {
      setShow(false);

      if (!message.draftSaved && prevConversationId) {
        if (onSaveDraft) onSaveDraft({
          conversationId: prevConversationId,
          message,
        });
      }

      if (draftMessage) {
        setMessage({
          content: markdownToEditor(draftMessage.content),
          attachments: draftMessage.attachments,
          draftId: draftMessage.id,
          draftSaved: true,
        });
      }

      if (!draftMessage && message.content?.getCurrentContent()?.getPlainText()?.trim() === '') {
        setMessage(DEFAULT_STATE);
      }
    },
    [prevConversationId, message, onSaveDraft, draftMessage]
  );

  const onDropRejected = useCallback(
    files => { // @TODO: use a custom alert
      files.forEach( ({ file }) => {
        window.alert(lexiqueAttachentError.type.replace('{{fileName}}', file.name).replace('{{acceptedFormat}}', acceptedFormat));
      })
    },
    [lexiqueAttachentError]
  );

  const onDrop = useCallback(
    async droppedFiles => {
      const filteredFiles = await transformDroppedFiles(message.attachments, droppedFiles, lexiqueAttachentError);
      if (isEmpty(filteredFiles)) return false;

      setMessage(m => ({
        ...m,
        draftSaved: false,
        attachments: [...m.attachments, ...filteredFiles],
      }))
    },
    [lexiqueAttachentError, message.attachments]
  );

  const handleRemove = useCallback(
    (a) => {

      const attachments = message.attachments.reduce((attachmentList, item) => {
        // Not the item we want to delete, just add it
        if (item.name !== a.name) {
          return [...attachmentList, item];
        }

        // The item we want to delete
        // If item has not an ID yet (not pushed on the server), just remove it from list
        if (!item.id) {
          return attachmentList;
        }

        // If it has an ID, mark it as to be destroyed
        return [
          ...attachmentList,
          {
            ...item,
            _destroy: true,
          },
        ];
      }, []);

      setMessage(m =>({
        ...m,
        attachments,
        draftSaved: false,
      }));
    },
    [message.attachments]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected,
    accept: acceptedFiles,
    multiple: true,
  });

  const handleChangeContent = useCallback(
    (value) => {
      setMessage(m => ({
        ...m,
        draftSaved: false,
        content: value,
      }))
    },
    [],
  );

  const handlePasteContent = useCallback(
    () => {
      setMessage(m => ({
        ...m,
        draftSaved: false,
        content: pasteEditorStyles(message.content),
      }))
    },
    [message.content]
  );

  const handleSendMessage = useCallback(
    async () => {
      if (onSend) await onSend(message);
      setMessage(DEFAULT_STATE);
      setShow(false);
    },
    [onSend, message]
  );

  const rootProps = useMemo(
    () => {
      return getRootProps({
        onClick: e => e.stopPropagation(),
        className: cn(styles.chatFooter, {
          [styles.droppableWrapperActive]: isDragActive,
          [styles.active]: show,
        })
      })
    },
    [getRootProps, isDragActive, show]
  );

  const isDisabledSendButton = useMemo(
    () => {
      const plainText = message.content?.getCurrentContent()?.getPlainText();
      return plainText.trim() === '';
    },
    [message.content]
  );

  const initPlaceholder = useMemo(
    () => {
      const plainText = message.content?.getCurrentContent()?.getPlainText();
      return plainText.trim() === ''
        ? lexiqueChat.inputPlaceholder
        : plainText
    },
    [message.content, lexiqueChat.inputPlaceholder]
  );

  return (
    <div className={styles.footerContainer}>

      <div {...rootProps} onClick={showForm}>
        {!show && (
          <Text className={styles.inputPlaceholder}>
            <Truncate>
              {initPlaceholder}
            </Truncate>
          </Text>
        )}

        {show && (
          <ClickAwayListener onClickAway={hideForm} mouseEvent="onMouseDown">
            <div className={styles.wrapperForm} >
              <input {...getInputProps()} />
              <div className={styles.row}>
                <TextEditor
                  withoutToolbar={withoutToolbar}
                  editorState={message?.content}
                  updateEditorState={handleChangeContent}
                  onPaste={handlePasteContent}
                  lexique={lexiqueChat}
                  onOpenDropzone={open}
                  locale={locale}
                  focused
                />
                <Button
                  className={styles.sendButton}
                  icon="paper-plane"
                  size="big"
                  label={lexiqueChat.sendButton}
                  onClick={handleSendMessage}
                  disabled={isDisabledSendButton}
                />
              </div>
              <div className={styles.attachmentsWrapper}>
                {message?.attachments?.length > 0 && (
                  <Attachments
                    attachments={message.attachments}
                    onRemove={handleRemove}
                  />
                )}
              </div>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  )
};

ChatFooter.displayName = 'ChatFooter';

export default ChatFooter;

